import React from "react";
import { Row, Col, Divider, List } from "antd";
import { RouteComponentProps } from "react-router";


const may22Data = [
  {
    title: "Muster Roll and Wage Register",
    url:
      "https://drive.google.com/file/d/1dUxzDYcK8T03WzhdAJDGa7fFprIiu3xs/view"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/file/d/1COf70ekH1kvl4xIKZqfIszF2-paNlCC8/view"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/file/d/1kkqe_8nDz4J5t3YdrfLYKzX1WwgNKfQl/view"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/file/d/142jE8famy_0MeFTATfvSNdwGL1r00-OU/view"
  }
];

const jun22Data = [
  {
    title: "Muster Roll and Wage Register",
    url:
      "https://drive.google.com/file/d/1lGoINfg-ODLbSEhaaxKmXSTQfQd_ikLi/view"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/file/d/1KptN987tNayhbDb8rePJca0ipbv9sT6p/view"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/file/d/1tqIKq5RSWERNkshyaWrna535DuHBumuM/view"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/file/d/1Tu9WWSXMX0m4pK_QdhlY4FxASYjrkUBx/view"
  }
];

const july22Data = [
  {
    title: "Muster Roll and Wage Register",
    url:
      "https://drive.google.com/file/d/1sje-lPk3EThG397fZMIZyvVYzvMQ_H5H/view"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/file/d/1mSMSZRtozTcmAbhC1xTD0pIeHFuEGQZC/view"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/file/d/1CqMdijkFXc3aczJdXi32H7FuMx28gWCr/view"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/file/d/1YKGiXoZPbpIpCn20w4V-0ZrdFTZSte4H/view"
  }
];

const aug22Data = [
  {
    title: "Muster Roll and Wage Register",
    url:
      "https://drive.google.com/file/d/1BeX81kdla6cwPnFVtbcZK9YGL6yGZdEY/view"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/file/d/1wik7jdG6xksm-9GIP2H4UdILLTmu3Ca4/view"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/file/d/1PGY15lN9LGuwO3fwvxYuADjLTg3KsIZ1/view"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/file/d/1JzzzhRIUDtyqbvHa7qd8Xhlb0TnJeliW/view"
  }
];

const sept22Data = [
  {
    title: "Muster Roll and Wage Register",
    url:
      "https://drive.google.com/file/d/1PGGThEw91e08gchBvSC3YXhqd0jLy11j/view"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/file/d/1KZtPrVwik7BFVYvOmvqcBXALrrg39pFk/view"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/file/d/1FpRvp_OCZ9-qumeXbVPh7FzO7RyYE-y-/view"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/file/d/1TIe6zW-m2ffq0AM05KDBFiuAUxMnaLEB/view"
  }
];

const oct22Data = [
  {
    title: "Muster Roll and Wage Register",
    url:
      "https://drive.google.com/file/d/1o3uVnka8IZCnpUax4zTK4e1UGBxuVSXu/view"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/file/d/1FbWfHzmIsIlDSLHX8aG_ttVNt0mazJB_/view"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/file/d/1pe2_AldgNvjPm-OsivCy7fIf7xfagwSd/view"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/file/d/1t3I6V076hewTZO-kSEkNiQ6hd6-JtyUO/view"
  }
];

const Nov22Data = [
  {
    title: "Muster Roll and Wage Register",
    url:
      "https://drive.google.com/file/d/1vN6tUT1xUUtFn5fuFdgXfvLTZXxyygiz/view"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/file/d/1xqVjEgK8pdjRnImeweoZ8xq3SbRcYEZX/view"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/file/d/1Jh-T6phMLXhUt0qYNJLVyF9mo0pqZPQs/view"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/file/d/1JkwFxwFkb-t5y27vjswVW0e89v6R8FKG/view"
  }
];

const Dec22Data = [
  {
    title: "Muster Roll and Wage Register",
    url:
      "https://drive.google.com/file/d/1VglBSnDP1hcM6mZth8qirW2MzX9MKrVr/view"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/file/d/15rs8xsylqbcDAluu9rx92HxYfueoMP0j/view"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/file/d/1ULdoCmCPYcq_3qWfuzjUJtG3METaX5Cl/view"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/file/d/1BFDw5hgyZorYRCcdIWrhtrsRAIPFH9_B/view"
  }
];

const Jan23Data = [
  {
    title: "Muster Roll and Wage Register",
    url:
      "https://drive.google.com/file/d/1JfDqkROdvbK4yK7mqi99RmaOHhJNv0r-/view"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/file/d/1sMJY29Yq5WWbQEre2YPtkAyxHYf6lDzq/view"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/file/d/1lKeMv6bzGemdrVIaQ3qzqOThiUESPOQM/view"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/file/d/1suRhBH9i5dgvKBUpFetR3d9Uv0H6WTmj/view"
  }
];

const feb23data = [
  {
    title: "Muster Roll and Wage Register",
    url:
      "https://drive.google.com/file/d/1dnOVIIfXRPs3Ur6kUN0I2GN2hpI-bfLK/view"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/file/d/1CDO4vfjkIwV_XLR2qGujBazudPtmZFw1/view"
  },
  {
    title: "ESIC Paid Challan",
    url:
    "https://drive.google.com/file/d/1AxQ3iVpnI9s3S_JlwrLYHnAPwH72pu4K/view"
  }
  ,
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/file/d/1bIEd1rVB2_Zz5zhtSUGHf629iKQsdUQM/view"
  }
];

const march23data = [
  {
    title: "Muster Roll and Wage Register",
    url:
      "https://drive.google.com/file/d/1HbyjOJITAiEhZm4Fjib-TCoigGLSUjVX/view"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/file/d/1-cKjtP0U-T9I4ETgI9Va94aFn6AQ1mjM/view"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/file/d/1yoHQPd5LlQdPaEOX1qrKya8b9GYzdCoi/view"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/file/d/13w_8bHZSDPoxZ3fLRB1VUg1LPPK3uZSs/view"
  }
];

const april23data = [
  {
    title: "Muster Roll and Wage Register",
    url:
      "https://drive.google.com/file/d/14F6UyGjhbcihDtnUHagGQcY8SSbUjJVp/view"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/file/d/1VvCvNZ8lslbHDwoalvV89W_dWAghZwiX/view"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/file/d/1XGxdIpuEmia9T5_VF9jYjdKMlNkoGj7G/view"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/file/d/11al66hHWbcsMuFBl3F3cqySzLccXyv-O/view"
  }
];

const may23data = [
  {
    title: "Muster Roll and Wage Register",
    url:
      "https://drive.google.com/file/d/1MDcKBeNrxf3OUyGY0FjN86TxyjKAwKdk/view"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/file/d/1N3RG3janF8puoNslbN_zN_LHfplztch3/view"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/file/d/16Yd-wWybNl0jmAb8vWaxEJsqaWO5Ppv6/view"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/file/d/1HK8Rp5KisoQtIjoP4rJYqgwJV7l81x9G/view"
  }
];

const june23data = [
  {
    title: "Muster Roll and Wage Register",
    url:
      "https://drive.google.com/file/d/1FESIi5a5P-RIjtTcBcAC1GQqipYB3Rbu/view?usp=sharing"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/file/d/1-Fv90gUUb-491TvcH2YC5U3OIT_32JNo/view?usp=sharing"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/file/d/13Msp5SNJhQpCY7y-4bG763DietQfdkjz/view?usp=sharing"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/file/d/1gcGr8Zh_zO364dmvBjumcFeNhyFa9yjc/view?usp=sharing"
  }
];

const july23data = [
  {
    title: "Muster Roll and Wage Register",
    url:
      "https://drive.google.com/file/d/15tXXrmvcjV4HmHdFYrMMp1d-IPNQNCbi/view?usp=drive_link"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/file/d/1H4MJUv5fiRw-dGDIDrkL0B0mMwERgGi5/view?usp=drive_link"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/file/d/18CqTxi1dseC-0EsT4jBXXloNhL1YxGWU/view?usp=drive_link"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/file/d/11Or7O79-jPsmHOtGnf7u9ruol8Xso1qS/view?usp=drive_link"
  }
];

const aug23data = [
  {
    title: "Muster Roll and Wage Register",
    url:
      "https://drive.google.com/file/d/1ydpcc4SHTW7ztD-5Cr38OSVHiL7ehWOI/view?usp=share_link"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/file/d/1ZAzwIAMQnAImJfHX-t84ZgoWZ26-0eSM/view?usp=share_link"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/file/d/1kZyjoXRtnUUk1vequpENOpksDUkV5KzD/view?usp=share_link"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/file/d/1d38wf7zuudM2OXroLOFRy8xc-NwvVOVO/view?usp=share_link"
  }
];

const sept23data = [
  {
    title: "Muster Roll and Wage Register",
    url:
      "https://drive.google.com/file/d/1rPa3QG3uNRcz6e4bl2cpcQPxX_JCDvVP/view?usp=sharing"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/file/d/1g-U7CFiBxGwgY08RmzIuES2XcOgrq4lq/view?usp=sharing"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/file/d/1Lp3sMmLBKC_mlflPbU0TzLMEs5WrY6dj/view?usp=sharing"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/file/d/18JkZbYtYD31h_m82ibqVal92m_JdUWWI/view?usp=sharing"
  }
];

const oct23Data = 
[
  {
    title: "Muster Roll and Wage Register",
    url:
      "https://drive.google.com/file/d/1-JiTznDM667VHT242vDkiiSQ8IpDpAWa/view?usp=sharing"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/file/d/1diFYN3XHeaCg5byeHqRm2g6-4DT-Jy6G/view?usp=sharing"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/file/d/1vtPGWqqWvHaPpdWGqK61mjTHsXqn8UNl/view?usp=sharing"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/file/d/1vtPGWqqWvHaPpdWGqK61mjTHsXqn8UNl/view?usp=sharing"
  }
];

const nov23Data = 
[
  {
    title: "Muster Roll and Wage Register",
    url:
      "https://drive.google.com/file/d/1PzT8RU6vSxYPpERp080Bg7m11wBhuG8Q/view?usp=sharing"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/file/d/1Kq7kmolNI3f304LZFYZF1TEDHxnW_QM0/view?usp=sharing"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/file/d/1UKfNh70S8K1IFEIha0fOvVm3KPLMGmr5/view?usp=sharing"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/file/d/13Sw9SdmVanRT43PIeQRao-KamTMBgOhY/view?usp=sharing"
  }
];

const dec23Data = 
[
  {
    title: "Muster Roll and Wage Register",
    url:
      "https://drive.google.com/file/d/1jnZ0SpvT-0QW-CUs3hlf4rBv8wJqLrSD/view?usp=sharing"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/file/d/1PUMYqo_C_zI736nHk9w_1L-KrdIltNKT/view?usp=sharing"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/file/d/1hkTdiDuhLm_I_lwPIJ0Nqadiux-UPL-u/view?usp=sharing"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/file/d/1YtfroCMfFIpVrDpwz_6D__4-BFcXRW4U/view?usp=sharing"
  }
];

const jan24Data = 
[
  {
    title: "Muster Roll and Wage Register",
    url:
      "https://drive.google.com/file/d/1g_YhpA4uy2G_5j8e_mshSnsYSS3d-1G3/view?usp=sharing"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/file/d/1SwejHz__INPR4fVWisacnyneNk3ra_f2/view?usp=sharing"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/file/d/1jzyKYNyUr70kNU7N0RlI1b7DrDn0mTKr/view?usp=sharing"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/file/d/1AD_PQ5ezmfRC5iF9bPkkApQb_vYLFipj/view?usp=sharing"
  }
];

const LivspaceRegisters: React.FC<RouteComponentProps> = ({
  history
}: RouteComponentProps) => {
  return (
    <div
      style={{
        width: "100%",
        background: "white",
        margin: "119px",
        marginTop: "0px",
        marginBottom: "0px",
        padding: "50px"
      }}
    >
      <Row type="flex">
        <Col span={12}>
          <img
            src={
              "https://olx-notifications.s3.ap-southeast-1.amazonaws.com/resources/images/aasaanjobs-logo.png"
            }
            style={{ marginLeft: "10px", width: "125px" }}
          />
        </Col>
        <Col span={12} style={{ textAlign: "right" }}>
          <img
            src={
              "https://olx-notifications.s3.ap-southeast-1.amazonaws.com/resources/images/betterplace_select_logo.png"
            }
            style={{ marginLeft: "10px", width: "125px", marginTop: "-15px" }}
          />
        </Col>
      </Row>
      <Divider
        style={{
          margin: "0px",
          background: "black",
          height: "1px",
          marginTop: "5px"
        }}
      />
      <Row>
        <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                May , 2022
              </div>
            }
            footer={null}
            bordered
            dataSource={may22Data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
        <Col span={2}></Col>
        <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                June , 2022
              </div>
            }
            footer={null}
            bordered
            dataSource={jun22Data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                July, 2022
              </div>
            }
            footer={null}
            bordered
            dataSource={july22Data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
        <Col span={2}></Col>
        <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                Aug , 2022
              </div>
            }
            footer={null}
            bordered
            dataSource={aug22Data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                September, 2022
              </div>
            }
            footer={null}
            bordered
            dataSource={sept22Data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
        <Col span={2}></Col>
        <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                October , 2022
              </div>
            }
            footer={null}
            bordered
            dataSource={oct22Data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                November, 2022
              </div>
            }
            footer={null}
            bordered
            dataSource={Nov22Data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
        <Col span={2}></Col>
        <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                December, 2022
              </div>
            }
            footer={null}
            bordered
            dataSource={Dec22Data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                January, 2023
              </div>
            }
            footer={null}
            bordered
            dataSource={Jan23Data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
         <Col span={2}></Col>
        <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                Febraury, 2023
              </div>
            }
            footer={null}
            bordered
            dataSource={feb23data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                March, 2023
              </div>
            }
            footer={null}
            bordered
            dataSource={march23data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
         <Col span={2}></Col>
         <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                April, 2023
              </div>
            }
            footer={null}
            bordered
            dataSource={april23data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
      </Row>
      <Row>
      <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                May, 2023
              </div>
            }
            footer={null}
            bordered
            dataSource={may23data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
        <Col span={2}/>
        <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                June, 2023
              </div>
            }
            footer={null}
            bordered
            dataSource={june23data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
      </Row>
      <Row>
      <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                July, 2023
              </div>
            }
            footer={null}
            bordered
            dataSource={july23data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
        <Col span={2}/>
        <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                August, 2023
              </div>
            }
            footer={null}
            bordered
            dataSource={aug23data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
      <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                Septmember, 2023
              </div>
            }
            footer={null}
            bordered
            dataSource={sept23data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
        <Col span={2}/>
        <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                October, 2023
              </div>
            }
            footer={null}
            bordered
            dataSource={oct23Data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
      </Row>
      <Row>
      <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                November, 2023
              </div>
            }
            footer={null}
            bordered
            dataSource={nov23Data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
        <Col span={2}/>
        <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                December, 2023
              </div>
            }
            footer={null}
            bordered
            dataSource={dec23Data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
      </Row>
      <Row>
      <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                January, 2024
              </div>
            }
            footer={null}
            bordered
            dataSource={jan24Data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
      </Row>
        
      <Divider
        style={{
          margin: "0px",
          background: "black",
          height: "1px",
          marginTop: "37px"
        }}
      />
      <Row>
        <Col style={{ textAlign: "center", marginTop: "7px" }}>
          *This webpage is property of Aasaanjobs Pvt. Ltd.
        </Col>
      </Row>
    </div>
  );
};
export default LivspaceRegisters;
