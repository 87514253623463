import React from "react";
import { Row, Col, Divider, List } from "antd";
import { RouteComponentProps } from "react-router";

const April24data = [
  {
    title: "Muster Roll and Wage Register",
    url:
      "https://drive.google.com/drive/folders/1bkLpdhzX_SHdW-XiQUHi5J6DKUgnh1PH?usp=sharing"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/drive/folders/1_ulYAYKlkrw3h2_LDHp_qqvGDstJwUR_?usp=sharing"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/drive/folders/1sJtAB1hbJSUvc8H3ud5ivQQB1LcJUczg?usp=sharing"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/drive/folders/1bPwzS4AQfVX-sUby44szGUmHNKgplM9F?usp=sharing"
  }
]

const March24data = [
  {
    title: "Muster Roll and Wage Register",
    url:
      "https://drive.google.com/drive/folders/1cRK32NWnssCIgxVAMRkEgxI0Q_llpMLJ?usp=drive_link"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/drive/folders/1W4K_fspolViNgMR2qF8aZ-LqdZkxoAQs?usp=drive_link"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/drive/folders/1mwIMHlavqfwe_-wkdmFcyLYp5YSb9FO3?usp=drive_link"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/drive/folders/1Kg4X0iMD1aXc1RCnCCEMkqv75eNzMOdR?usp=drive_link"
  }
]

const Feb24data = [
  {
    title: "Muster Roll and Wage Register",
    url:
      "https://drive.google.com/drive/folders/1vQpKCpevpS453DluwKGNfZEd_tU2w6Dy?usp=drive_link"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/drive/folders/190W6IYqDWEQCDeXZ5VzEY2ivbL8slBJ_?usp=drive_link"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/drive/folders/1Api2Wghc_UbW3gHHpkuyEuKR-WaXTWNm?usp=drive_link"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/drive/folders/1OBHTWmm9auUDdqyRTDvMwdirVCFU0-dU?usp=drive_link"
  }
]

const Jan24data = [
  {
    title: "Muster Roll and Wage Register",
    url:
      "https://drive.google.com/drive/folders/1Vwt0MA0J0eV9xR6I3pjuAf2Bx2eNwmHY?usp=sharing"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/drive/folders/1YumzAzrQmh8eNxmyc4RSwPihRBPh-ieK?usp=sharing"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/drive/folders/1EJhAwA-t1jynynRb-jiMj8Gpt6pX18cZ?usp=sharing"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/drive/folders/12lmE2MXuntSOeUHqdPZ4GND_-VpCim5j?usp=sharing"
  }
]

const dec23data = [
  {
    title: "Muster Roll and Wage Register",
    url:
      "https://drive.google.com/drive/folders/1PClylpXHEG9VPTMNa-3AlSg2IQy8VipW?usp=sharing"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/drive/folders/1gP5vPyNAzu1ngkMIyJuISRtwA3nSFhAH?usp=sharing"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/drive/folders/1jwBxWbw6Fj4PeeRogpfxNbXkznh-FwC5?usp=sharing"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/drive/folders/1RIg_rag40PNUBE4Ff-SAiV6lHzNI42yp?usp=sharing"
  }
]

const nov23data = [
  {
    title: "Muster Roll and Wage Register",
    url:
      "https://drive.google.com/drive/folders/1GNSn7DRno-1Wso6fl9yiJ64I9o5_DSHJ?usp=sharing"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/drive/folders/10-ULx0Rr2EkQlKO6MtpYKNMKZNEiGSV-?usp=sharing"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/drive/folders/1BO3FHZxiExtqQKZadQypmxw-w3l-D3I0?usp=sharing"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/drive/folders/1B8fhM2rcFZPbCzD5ha4cZdHBe440CBKV?usp=sharing"
  }
]

const oct23data = [
  {
    title: "Muster Roll and Wage Register",
    url:
      "https://drive.google.com/drive/folders/1Sfnd_GN2FsyMQvQw4nSsd72iI4eqFwTx?usp=sharing"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/drive/folders/1appqGxCVtlFl1-oJZfUxuP8-Mz2Wtk_S?usp=sharing"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/drive/folders/1euzhQ2QWKHOiyoSjbFARdpikaKJ3MLPJ?usp=sharing"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/drive/folders/1xBcDHSPDhTb14vFowJNVTHxB_WJYsCFX?usp=sharing"
  }
]

const sept23data = [
  {
    title: "Muster Roll and Wage Register",
    url:
      "https://drive.google.com/drive/folders/1Z3J5zet1L61mr-HslvK4-iHDJ6YDiV05?usp=sharing"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/drive/folders/1ztniPFwj14s1VXkW8exVkPyfDj2QTTAA?usp=sharing"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/drive/folders/1KTg0e3_-Ht95JfkMQU9alrQyIAvkk8Np?usp=sharing"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/drive/folders/1Gs4eNCUf2j2B1sd7_947A8Ly2ktGqHM_?usp=sharing"
  }
]

const aug23data = [
  {
    title: "Muster Roll and Wage Register",
    url:
      "https://drive.google.com/drive/folders/10oT9EZynfCe0giqfdwsRQQGBg-lFSYkR?usp=share_link"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/file/d/1D_xiSKUT_noZe8ivF46_rqQSAcsOtcLc/view?usp=share_link"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/file/d/1tq0WTOSOA7T_u5Ht2dGVzHeQjyXhTgwj/view?usp=share_link"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/drive/folders/12x43v8FYqqyfK3CCylkq6B6YaljXVGyK?usp=share_link"
  }
]

const july23data = [
  {
    title: "Muster Roll and Wage Register",
    url:
      "https://drive.google.com/drive/folders/17aPLQdS-NaF5Y9N-BS74eWCUNyhM6uck?usp=drive_link"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/file/d/1e81gzC0ZMhUQnmZHy02txUt9mZ3mcA-S/view?usp=drive_link"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/file/d/1nxpDvpCsJ80b-WIcli6Pue1psCXILRnH/view?usp=drive_link"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/drive/folders/1jAOHVkjdFkVNAvSAaV-sBu74xcaw7jum?usp=drive_link"
  }
]

const june23data = [
  {
    title: "Muster Roll and Wage Register",
    url:
      "https://drive.google.com/drive/folders/1E1sUwNBRlPrK47fggUdEHVoWiSG3fM6c?usp=drive_link"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/file/d/1qpABv5ejwZ-3EOjcHEuTyq8NGzNW6nVe/view?usp=drive_link"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/file/d/1fCSHHPdNAymN7T4A6pWzwWk_mQ7yU4A9/view?usp=drive_link"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/drive/folders/1TgCKQbwcRmw1LCZsDbVGGi9mJE5aAQ1q?usp=drive_link"
  }
]

const may23data = [
  {
    title: "Muster Roll and Wage Register",
    url:
      "https://drive.google.com/drive/folders/1-KvWZ3z12ZWBIdqqF06E5y931vcDxWnR?usp=drive_link"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/file/d/191GJoiQ0aLJCZCeOfz7mG1jb_zSt8GkG/view?usp=drive_link"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/file/d/1w2zPMnRrCbDnUWUAWYbrzFGTtE1O94A4/view?usp=drive_link"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/drive/folders/1-Ez2Aq2orVsHvd5f-UbDU3SpLDz37e5t"
  }
]

const April23data = [
  {
    title: "Muster Roll and Wage Register",
    url:
      "https://drive.google.com/drive/folders/1TtZ1p0syqSjkbItT1HGIVDpu6IW7bwXo"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/file/d/1CXQ4B8NSfCMJSt0iCBgk0ug-FrWixNlN/view?usp=sharing"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/file/d/1BywGgQQwxOSqXQHumC7vdb8uUOXfnQ6t/view?usp=sharing"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/drive/folders/1Tg6Yxwbfhht_2CKOeqQHCOYRWoBWrAND"
  }
]

const March23Data = [
  {
    title: "Muster Roll and Wage Register",
    url:
      "https://drive.google.com/drive/folders/1t23f5toIxlQcG4vjN1yh1Gikz291U8iI"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/file/d/1h4lyIh_I7zU_Z2os90JL6eRSlE8R4h9N/view?usp=share_link"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/file/d/1xtAnxAsagWO2LVBEX7wS5nyHjpixBkTD/view?usp=share_link"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/drive/folders/1ch0kkg6ZoZwAHvNHFt2z-BH0-AbhrsVK"
  }
]

const Feb23Data = [
  {
    title: "Muster Roll and Wage Register",
    url:
      "https://drive.google.com/drive/folders/15wZgjDap543UQQ8lbKCWJxT5zjkMOlGL"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/file/d/11ZhIKtOO8YmVab9akuNXtOyjsWtb_4QV/view?usp=sharing"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/file/d/1F7bpz7FaacZ2_x_bPBxA3pd8K7YFLBhk/view?usp=sharing"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/drive/folders/1l3_pZHjFIyNzph2S0VF9C1fE80kxBWNH"
  }
]

const Jan23Data = [
  {
    title: "Muster Roll and Wage Register",
    url:
      "https://drive.google.com/drive/folders/1ZqpziGm9Jh7b2jrmRirAJNKI2JsRi3DZ"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/file/d/1aey-UFmIg4Sh3gAtHifGCQy_YhDpcFAL/view?usp=sharing"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/file/d/1Afd1V1CT46vlUZArHoSeOz0GKzwFCPQq/view?usp=share_link"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/drive/folders/1SPM7iIanv019dcIgA7vg0R7gIGtShKI7"
  }
]; 

const Dec22Data = [
  {
    title: "Muster Roll and Wage Register",
    url:
      "https://drive.google.com/drive/folders/1B4j91dvz7_p6s8qXOV9X88lR788ETqjh?usp=share_link"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/file/d/1q0nPxGyRDYrGLO5kxdRglTXGdrpzHb2h/view?usp=share_link"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/file/d/1CiCp_3QFMko8vvBrPkNNRDMOOxGEeEcf/view?usp=share_link"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/drive/folders/1R4K2PiXr3inGU0BM0RXA9OZ3Rcpic13P?usp=share_link"
  }
];

const Nov22Data = [
  {
    title: "Muster Roll and Wage Register",
    url:
      "https://drive.google.com/drive/folders/1cFb89XPpJx-xqg4EqxwIbldZaDXlZyKo"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/drive/folders/1fp7uFjucNKJtv1kIVJo_a7f3Tmmr4o9F"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/drive/folders/1Di-CtiFf0_2LuVoiAMgtTwjjD3V1UlZz"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/drive/folders/1Nb_m_C5xJClcxAn1_Pzx6OJDmPm1-Jeo"
  }
];

const Oct22Data = [
  {
    title: "Muster Roll and Wage Register",
    url:
      "https://drive.google.com/drive/folders/1_O8yMe82llTTlZwERtFQSTCgun2ruDiT"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/drive/folders/1JjgQxoDrcI6X_-0SgXyJW0lK075nZT3E"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/drive/folders/1svtUV4PFvEbmgGugA14PmhZ-dV4x838Q"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/drive/folders/1cFVtez_Dzhs3Ehs9VehaiWgngZzcFrR3"
  }
];
const Sep22Data = [
  {
    title: "Muster Roll and Wage Register",
    url:
      "https://drive.google.com/drive/folders/1tJ8DqByo2RBRKfttag_1ZeoiYofnfOgw"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/drive/folders/1jPD-BFjLa3Ye4X3TKzczLTe7KniSJ3QX"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/drive/folders/1ku_IQkPwXh6hvmndaDH3YJ40TOJQrBZp"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/drive/folders/12WCg_voaZB6kEMyHZREFXDfLM8cFrTYF"
  }
];

const Aug22Data = [
  {
    title: "Muster Roll and Wage Register",
    url:
      "https://drive.google.com/drive/folders/1o5FFF7pL1k6aHQ4H0KoiiaNMWdcUEbUn"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/drive/folders/1xNkk31PvXEIL4EmEZ1rgA3RqwUrM3HqD"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/drive/folders/1cDyDkMIAl2O6Ohpt1z1mnm79FXlErx_Z"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/drive/folders/1N3JuBRh5Hq3MPaGrrrt5IARJzb5YiDlP"
  }
];

const July22Data = [
  {
    title: "Muster Roll and Wage Register",
    url:
      "https://drive.google.com/drive/folders/1545P2H-bb0DtSYGyIm7O2ERqJsGQ2NaT"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/drive/folders/1REvpzJDa8l2qSd2z7k4iID8W9r21wSli"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/drive/folders/1MYiw45ohhzjy73YaoRCqUr8nMrX4YkIK"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/drive/folders/1nEB20SDf30vT5OkCM3qtKFdyDt4I4uPW"
  }
];

const June22Data = [
  {
    title: "Muster Roll and Wage Register",
    url:
      "https://drive.google.com/drive/folders/1c8B9UTG3r0dFpYDM5sPCIWNpFOi7JoeK"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/drive/folders/19c1Jgr3s43i2aBcfjc0abi6Efu1iq2Je"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/drive/folders/1fAGxSlEN3tPvgRUx3Ke2e3vQEY05uaar"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/drive/folders/1AZzr6JiQq2jshDgahvOCgLFyz-Vu_ISc"
  }
];

const May22Data = [
  {
    title: "Muster Roll and Wage Register",
    url:
      "https://drive.google.com/drive/folders/1vf5jGCGcqu0AN99OVhk2w0wdANzMt8u5"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/drive/folders/1IOimdAMvUbfje7Kx5SqqwrkLgXVVbH26"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/drive/folders/1w0wYXuIpa7VOaawa_XVCPg9cinc1Vc8E"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/drive/folders/14_XBx8ej5hwjM0QXcUA7fc6e7je0Gg_u"
  }
];

const April22Data = [
  {
    title: "Muster Roll and Wage Register",
    url:
      "https://drive.google.com/drive/folders/1wJjJ3oK98Tw_jtV53sR7LueRLx2xMx__?usp=sharing"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/drive/folders/1B0TfKo2yBHvu-xl27LDkqLSQg3baDRQT?usp=sharing"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/drive/folders/1WeA3Wir123tgvHgO7YNNbB1e2rBkNRqm?usp=sharing"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/drive/folders/1ZUS1_YltY9zhR2aARz5xNp2Fvxe8o5yf?usp=sharing"
  }
];

const Mar22Data = [
  {
    title: "Muster Roll and Wage Register",
    url:
      "https://drive.google.com/drive/folders/1MOtPKP39RZRPP4bPxgMgi_nQvCMQBLWl?usp=sharing"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/drive/folders/14MU3YWj7CPbpgYvyq09XIIVQ4J-xYTkQ?usp=sharing"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/drive/folders/1Rjo72CAjHcWxS3_vIz_MtI2GKUgUv3Ms?usp=sharing"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/drive/folders/1kiqKdlSibhrBShrRrH79plSpWLkgLdR8?usp=sharing"
  }
];

const Feb22Data = [
  {
    title: "Muster Roll and Wage Register",
    url:
      "https://drive.google.com/file/d/1aItQS8iHvA20dBAhvTuP87u3doFtGQ3v/view?usp=sharing"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/file/d/1HK03mynW9tmQBGCgijnjj2IcffnG4_Uf/view?usp=sharing"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/file/d/13Jfo0H56iVeM3oTRaS1i89tZqViFWRN4/view?usp=sharing"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/file/d/1M-5fAnCPcaJMxbx3X64smaMjsPvikPZH/view?usp=sharing"
  }
];

const Jan22Data = [
  {
    title: "Muster Roll and Wage Register",
    url:
      "https://drive.google.com/file/d/1CuL3uVvedgJU2vUTEB3gKd6cVEfFWb7b/view?usp=sharing"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/file/d/1DT-RRDQDWA3hZ6I175LDClTEdY4yTbO9/view?usp=sharing"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/file/d/19I4Pd8LOZtIgNnXzXlpcjNX13OQFCC15/view?usp=sharing"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/file/d/1_nsmfBXq12NNyARNfzsyyWu_H3qbEAKK/view?usp=sharing"
  }
];
const Dec21Data = [
  {
    title: "Muster Roll and Wage Register",
    url:
      "https://drive.google.com/file/d/1HStGNs-AMhFgkHF8OSBo9MwAXwc2Q4oy/view?usp=sharing"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/file/d/1_56DT6iEKd7NkKrs4TYw41lhblHIZLyG/view?usp=sharing"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/file/d/1ENfIB--2tRMOmBN06TcDNtrgxQQ9kIlO/view?usp=sharing"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/file/d/1uRCe5D2fqabggcX-B1INuL5RVqF5xFrK/view?usp=sharing"
  }
];
const nov21Data = [
  {
    title: "Muster Roll and Wage Register",
    url:
      "https://drive.google.com/file/d/1nhQ6Vy_Lo0aou_WxmqJVL9Qmn2AXVT4E/view?usp=sharing"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/file/d/1UwkYj1mw4mJCHEClfsTLC10tp47Y34Kn/view?usp=sharing"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/file/d/1wLlj6F4ojob3YzEKQxVH_Cs8KK7A6Smx/view?usp=sharing"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/file/d/1UsRdalExLpEXUlfdqAlVmFS4_GVEIdDf/view?usp=sharing"
  }
];

const oct21Data = [
  {
    title: "Muster Roll and Wage Register",
    url:
      "https://drive.google.com/file/d/1DA36bZebPh5wEd1t1aCK6I7F8R9kHvlI/view?usp=sharing"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/file/d/1Dt0QWfCUyfPDxRyE2Q5aX0ump8C3gjWT/view?usp=sharing"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/file/d/1qEZnDtOpz3Q7F6KY3Ey2GfOec-ZxkSBV/view?usp=sharing"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/file/d/1zwcLc2BWFXokMJGN3OxvJyHt6a2vIA-Y/view?usp=sharing"
  }
];
const AmazonRegisters: React.FC<RouteComponentProps> = ({
  history
}: RouteComponentProps) => {
  return (
    <div
      style={{
        width: "100%",
        background: "white",
        margin: "119px",
        marginTop: "0px",
        marginBottom: "0px",
        padding: "50px"
      }}
    >
      <Row type="flex">
        <Col span={12}>
          <img
            src={
              "https://olx-notifications.s3.ap-southeast-1.amazonaws.com/resources/images/aasaanjobs-logo.png"
            }
            style={{ marginLeft: "10px", width: "125px" }}
          />
        </Col>
        <Col span={12} style={{ textAlign: "right" }}>
          <img
            src={
              "https://olx-notifications.s3.ap-southeast-1.amazonaws.com/resources/images/betterplace_select_logo.png"
            }
            style={{ marginLeft: "10px", width: "125px", marginTop: "-15px" }}
          />
        </Col>
      </Row>
      <Divider
        style={{
          margin: "0px",
          background: "black",
          height: "1px",
          marginTop: "5px"
        }}
      />
      <Row>
        <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                October , 2021
              </div>
            }
            footer={null}
            bordered
            dataSource={oct21Data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
        <Col span={2}></Col>
        <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                November , 2021
              </div>
            }
            footer={null}
            bordered
            dataSource={nov21Data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                December , 2021
              </div>
            }
            footer={null}
            bordered
            dataSource={Dec21Data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
        <Col span={2}></Col>
        <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                January , 2022
              </div>
            }
            footer={null}
            bordered
            dataSource={Jan22Data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                February , 2022
              </div>
            }
            footer={null}
            bordered
            dataSource={Feb22Data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
        <Col span={2}></Col>
        <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                March , 2022
              </div>
            }
            footer={null}
            bordered
            dataSource={Mar22Data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                April , 2022
              </div>
            }
            footer={null}
            bordered
            dataSource={April22Data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
        <Col span={2}></Col>
        <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                May , 2022
              </div>
            }
            footer={null}
            bordered
            dataSource={May22Data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                June , 2022
              </div>
            }
            footer={null}
            bordered
            dataSource={June22Data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
        <Col span={2}></Col>
        <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                July , 2022
              </div>
            }
            footer={null}
            bordered
            dataSource={July22Data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                Aug , 2022
              </div>
            }
            footer={null}
            bordered
            dataSource={Aug22Data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
        <Col span={2}></Col>
        <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                September , 2022
              </div>
            }
            footer={null}
            bordered
            dataSource={Sep22Data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                October , 2022
              </div>
            }
            footer={null}
            bordered
            dataSource={Oct22Data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
        <Col span={2}></Col>
        <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                November , 2022
              </div>
            }
            footer={null}
            bordered
            dataSource={Nov22Data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                December , 2022
              </div>
            }
            footer={null}
            bordered
            dataSource={Dec22Data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
        <Col span={2}></Col>
        <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                January , 2023
              </div>
            }
            footer={null}
            bordered
            dataSource={Jan23Data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>  
      </Row>
      <Row>
        <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                February , 2023
              </div>
            }
            footer={null}
            bordered
            dataSource={Feb23Data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
        <Col span={2}></Col>
        <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                March , 2023
              </div>
            }
            footer={null}
            bordered
            dataSource={March23Data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                April , 2023
              </div>
            }
            footer={null}
            bordered
            dataSource={April23data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
        <Col span={2}></Col>
        <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                May , 2023
              </div>
            }
            footer={null}
            bordered
            dataSource={may23data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
      </Row>
      <Row>
      <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                June , 2023
              </div>
            }
            footer={null}
            bordered
            dataSource={june23data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
        <Col span={2}></Col>
        <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                July , 2023
              </div>
            }
            footer={null}
            bordered
            dataSource={july23data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
      </Row>
      <Row>
      <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                August , 2023
              </div>
            }
            footer={null}
            bordered
            dataSource={aug23data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
        <Col span={2}></Col>
        <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                September , 2023
              </div>
            }
            footer={null}
            bordered
            dataSource={sept23data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
      </Row>
      <Row>
      <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                October , 2023
              </div>
            }
            footer={null}
            bordered
            dataSource={oct23data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
      <Col span={2}></Col>
      <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                Novmember , 2023
              </div>
            }
            footer={null}
            bordered
            dataSource={nov23data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
      </Row>
      <Row>
      <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                December , 2023
              </div>
            }
            footer={null}
            bordered
            dataSource={dec23data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
        <Col span={2}></Col>
      <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                Janauary , 2024
              </div>
            }
            footer={null}
            bordered
            dataSource={Jan24data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
      </Row>
      <Row>
      <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                Febuary , 2024
              </div>
            }
            footer={null}
            bordered
            dataSource={Feb24data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
        <Col span={2}></Col>
        <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                March , 2024
              </div>
            }
            footer={null}
            bordered
            dataSource={March24data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
      </Row>
      <Row>
      <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                April , 2024
              </div>
            }
            footer={null}
            bordered
            dataSource={April24data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
      </Row>

      <Divider
        style={{
          margin: "0px",
          background: "black",
          height: "1px",
          marginTop: "37px"
        }}
      />
      <Row>
        <Col style={{ textAlign: "center", marginTop: "7px" }}>
          *This webpage is property of Aasaanjobs Pvt. Ltd.
        </Col>
      </Row>
    </div>
  );
};
export default AmazonRegisters;
